'use client';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

const useResponsive = () => {
    const [screenWidth, setScreenWidth] = useState(0);
    const [screenHeight, setScreenHeight] = useState(0);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isTabletBreakpoint = useMediaQuery('(max-width: 1280px)');
    const isDesktop = useMediaQuery('(min-width: 1280px)');
    useEffect(() => {
        const updateWidth = () => {
            setScreenWidth(window?.innerWidth);
            setScreenHeight(window?.innerHeight);
        };

        window.addEventListener('resize', updateWidth);
        updateWidth(); // Initial update

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);
    return {
        isMobile,
        isTablet: isTabletBreakpoint && !isMobile,
        isDesktop,
        screenWidth,
        screenHeight,
    };
};

export default useResponsive;

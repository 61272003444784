import { usePostHogCapture } from '@/components/Providers/PostHogProvider';
import { useAnalytics } from '@/hooks/useAnalytics';
import useJiminiQuery from '@/hooks/useJiminiQuery';
import apiAnalyzer from '@/requests/apis/analyzer';
import apiChatMessage from '@/requests/apis/chatMessage';
import apiDocuments from '@/requests/apis/documents';
import { AnswerMatrixRaw } from '@/types/analyzer';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';
import React, { useMemo, useState } from 'react';
import { removeMarkdown } from '@/helpers/markdown';

const typeQuestionToReadableType = (type: string) => {
    if (type === 'summarization') {
        return 'synthèse';
    } else if (type === 'translation') {
        return 'traduction';
    } else if (type === 'factual') {
        return 'question_directe_sourcée';
    } else if (type === 'generic') {
        return 'requête_libre';
    } else {
        return 'synthèse';
    }
};

const useAnswerFeedback = (message: PublicMessageWithPreviousAndFiles) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadError, setDownloadError] = useState<boolean>(false);
    const [copiedSnackbarOpen, setCopiedSnackbarOpen] = useState(false);
    const [negativeFeedbackModalOpen, setNegativeFeedbackModalOpen] =
        useState(false);
    const { trackMessageAction } = useAnalytics();
    const {
        refetchConversationById,
        refetchMessageById,
        refetchConversations,
    } = useJiminiQuery();
    const { postHogCaptureWithUser } = usePostHogCapture();

    const handleDownload = async (
        markdown_content: string,
        target_format: 'pdf' | 'docx',
        name: string
    ) => {
        setIsDownloading(true);
        await apiDocuments.downloadAnswerFile({
            markdown_content,
            target_format,
            name,
            onError: () => {
                setIsDownloading(false);
                setDownloadError(true);
                setTimeout(() => {
                    setDownloadError(false);
                }, 5000);
            },
        });

        setIsDownloading(false);
    };
    const handleDownloadMatrice = async (
        message_id: string,
        target_format: 'xlsx' | 'concise' | 'detailed',
        name: string
    ) => {
        setIsDownloading(true);
        await apiDocuments.downloadAnswerFileMatrice({
            message_id,
            target_format,
            name,
            onError: () => {
                setIsDownloading(false);
                setDownloadError(true);
                setTimeout(() => {
                    setDownloadError(false);
                }, 5000);
            },
        });
        setIsDownloading(false);
    };

    const feedbackRequest = async (feedback: 1 | -1 | 0) => {
        return apiChatMessage.onFeedbackClick({
            messageId: message.id,
            onSuccess: () => {},
            data: {
                feedback: feedback,
            },
        });
    };

    const onThumbsUp = async () => {
        if (message.feedback === 1) {
            postHogCaptureWithUser({
                event: 'unselect-thumbs-up',
                context: {
                    request_id: message.id,
                    mission_type: message.type_question,
                },
            });

            await feedbackRequest(0);
        } else {
            postHogCaptureWithUser({
                event: 'thumbs-up',
                context: {
                    request_id: message.id,
                    mission_type: message.type_question,
                },
            });
            await feedbackRequest(1);
        }
        refetchMessageById(message.id).then();
    };

    const onThumbsDown = async () => {
        if (message.feedback === -1) {
            postHogCaptureWithUser({
                event: 'unselect-thumbs-down',
                context: {
                    request_id: message.id,
                    mission_type: message.type_question,
                },
            });
            await feedbackRequest(0);
        } else {
            postHogCaptureWithUser({
                event: 'thumbs-down',
                context: {
                    request_id: message.id,
                    mission_type: message.type_question,
                },
            });
            await feedbackRequest(-1);
        }
        refetchMessageById(message.id);
        setNegativeFeedbackModalOpen(true);
    };

    const onCopy = async () => {
        try {
            navigator.clipboard.writeText(await getClipBoardText());
        } catch (e) {
            return { error: 'clipboard copy failed' };
        }

        postHogCaptureWithUser({
            event: 'copy-answer',
            context: {
                request_id: message.id,
                mission_type: message.type_question,
            },
        });

        trackMessageAction(message.id, 'copied');
        setCopiedSnackbarOpen(true);
        return true;
    };

    const onRetry = async (message: PublicMessageWithPreviousAndFiles) => {
        postHogCaptureWithUser({
            event: 'regenerate-answer',
            context: {
                request_id: message.id,
                mission_type: message.type_question,
            },
        });

        await apiAnalyzer.retryMessage(message.id);

        refetchConversationById(message.conversation_id);
        refetchConversations();
    };

    const onErrorRetry = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        message: PublicMessageWithPreviousAndFiles
    ) => {
        e.stopPropagation();
        if (!message.id) {
            return;
        }
        await apiAnalyzer.onErrorRetry(message.id);

        refetchConversationById(message.conversation_id);
        refetchMessageById(message.id);
        refetchConversations();
    };

    // format subject part of the download name
    const sliceQuestionTitle = (questionTitle: string) => {
        if (questionTitle.length <= 25) {
            return questionTitle;
        } else {
            return `${questionTitle.slice(0, 15)}...${questionTitle.slice(
                -10
            )}`;
        }
    };
    const getLatestQuestionText = () => {
        if (message.type_question === 'summarization' && message.docs?.length) {
            const fileName = message.docs[0]?.name;
            return fileName
                ?.toLowerCase()
                .replace('.pdf', '')
                .replace('.docx', '')
                .replace('.odt', '')
                .replace('.doc', '');
        } else {
            return message.followup_to_message?.text ?? '';
        }
    };

    const onDownloadIconPress = async (
        downloadType?: 'detailed' | 'concise' | 'xlsx'
    ) => {
        const formattedDownloadName = `jimini_${typeQuestionToReadableType(
            message.type_question
        )}_${sliceQuestionTitle(getLatestQuestionText() || '')}`;
        const matriceBaseName = `matrice-d'analyse-${new Date().toLocaleDateString(
            'fr-FR'
        )}`;
        trackMessageAction(message.id, 'downloaded');

        postHogCaptureWithUser({
            event: 'download-answer',
            context: {
                request_id: message.id,
                mission_type: message.type_question,
            },
        });

        if (message.text && message.type_question !== 'matrice') {
            return handleDownload(message.text, 'docx', formattedDownloadName);
        } else if (
            message.text &&
            downloadType &&
            message.type_question === 'matrice'
        ) {
            return handleDownloadMatrice(
                message.id,
                downloadType,
                downloadType === 'xlsx'
                    ? `${matriceBaseName}.xlsx`
                    : `${matriceBaseName}.docx`
            );
        } else {
            return;
        }
    };

    const getClipBoardText = () => {
        if (message.type_question === 'matrice') {
            const textWithoutSensitiveInformation = removeSensitiveInformation(
                message.text
            );
            return convertToTSV(textWithoutSensitiveInformation);
        } else {
            return removeMarkdown(message.text);
        }
    };

    interface AnswerMatrixTransformed
        extends Omit<AnswerMatrixRaw, 'document' | 'sources' | 'sub_sources'> {
        doc_name?: string;
    }

    const removeSensitiveInformation = (
        matriceAnswerText: string
    ): AnswerMatrixTransformed[] => {
        const matriceAnswers: AnswerMatrixRaw[] = JSON.parse(matriceAnswerText);

        const transformedAnswers = matriceAnswers.map((matriceAnswer) => {
            const {
                document,
                sources,
                sub_sources,
                ...matriceAnswerWithoutDoc
            } = matriceAnswer;
            const matriceAnswerTransformed = {
                ...matriceAnswerWithoutDoc,
                doc_name: document?.name,
            };

            return matriceAnswerTransformed;
        });

        return transformedAnswers;
    };

    const answerMatrixTransformedKeyMappings: { [key: string]: string } = {
        column_title: 'Titre',
        concise_answer: 'Réponse concise',
        detailed_answer: 'Réponse détaillée',
        query: 'Requête',
        query_format: 'Format de sortie',
        doc_name: 'Nom du document',
    };

    const convertToTSV = (
        matriceAnswersTransformed: AnswerMatrixTransformed[]
    ) => {
        if (matriceAnswersTransformed.length === 0) return '';

        const escapeValue = (value: string) => {
            return value
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\r/g, '\\r');
        };

        const headers = Object.keys(matriceAnswersTransformed[0])
            .map((key) => answerMatrixTransformedKeyMappings[key])
            .join('\t');

        const rows = matriceAnswersTransformed
            .map((matriceAnswer) =>
                Object.values(matriceAnswer)
                    .map((value) => escapeValue(String(value)))
                    .join('\t')
            )
            .join('\n');

        return `${headers}\n${rows}`;
    };

    const feedbackStatus = useMemo(() => {
        if (message.feedback === 1) {
            return 'positive';
        } else if (message.feedback === -1) {
            return 'negative';
        } else {
            return null;
        }
    }, [message.feedback]);

    return {
        isDownloading,
        setIsDownloading,
        downloadError,
        setDownloadError,
        onThumbsUp,
        onThumbsDown,
        onCopy,
        onRetry,
        onErrorRetry,
        copiedSnackbarOpen,
        setCopiedSnackbarOpen,
        negativeFeedbackModalOpen,
        setNegativeFeedbackModalOpen,
        onDownloadIconPress,
        feedbackStatus,
    };
};

export default useAnswerFeedback;

import { PublicMessageWithPreviousAndFiles } from '@/types/requests';
import { callApi } from '../callApi';
import { SearchADBSortBy } from '@/components/Drawers/AnswerDrawer/SearchADB/types';
import { ChatMessageOnFeedbackClick } from '@/types/general';

const apiChatMessage = {
    getMessageById: async (
        messageId: string
    ): Promise<PublicMessageWithPreviousAndFiles> => {
        try {
            const message = await callApi(`/api/chat_message/${messageId}`);
            const messageJson: PublicMessageWithPreviousAndFiles =
                await message.json();
            return messageJson;
        } catch (e: any) {
            throw new Error(e);
        }
    },
    getSearchADBMessageById: async (
        messageId: string,
        sortedBy: SearchADBSortBy
    ): Promise<PublicMessageWithPreviousAndFiles> => {
        try {
            const message = await callApi(
                `/api/chat_message/${messageId}/search_adb?sort_by=${sortedBy}`
            );
            const messageJson: PublicMessageWithPreviousAndFiles =
                await message.json();
            return messageJson;
        } catch (e: any) {
            throw new Error(e);
        }
    },
    onFeedbackClick: async ({
        messageId,
        data,
        onSuccess,
    }: ChatMessageOnFeedbackClick) => {
        return callApi(`/api/chat_message/${messageId}/feedback`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((res) => {
            if (res.ok) {
                onSuccess();
            }
        });
    },
    onMessageAnalytics: async ({
        messageId,
        data,
        onSuccess,
    }: {
        messageId: string;
        data: {
            action: 'copied' | 'downloaded' | 'translated';
        };
        onSuccess: () => void;
    }) => {
        return callApi(`/api/chat_message/${messageId}/analytics`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                if (res?.ok) return res.json();
                else throw new Error(res.statusText);
            })
            .then(() => onSuccess())
            .catch(() => {});
    },
};
export default apiChatMessage;

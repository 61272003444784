'use client';
import React, { SVGProps } from 'react';

type InfiniteLoadingCircleType = {
    className?: string;
    classNameSize?: string;
    classNameCircle?: string;
};

const InfiniteLoadingCircle = ({
    className = '',
    classNameSize = '',
    classNameCircle = '',
    ...props
}: InfiniteLoadingCircleType & SVGProps<SVGSVGElement>) => {
    return (
        <svg
            className={`circular ${classNameSize || 'h-5 w-5'} ${className}`}
            viewBox='25 25 50 50'
            {...props}
        >
            <circle
                className={`path stroke-primary2 ${classNameCircle}`}
                cx='50'
                cy='50'
                r='20'
                fill='none'
                strokeWidth='5'
                strokeMiterlimit='10'
            />
        </svg>
    );
};

export default InfiniteLoadingCircle;

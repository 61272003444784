import React, {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useState,
} from 'react';
import { MissionsEnum, UseCaseType } from '@/types/analyzer';
import usePromptModulePageChange from '@/hooks/usePromptModulePageChange';
import {
    PublicLibraryFile,
    PublicMessageWithPreviousAndFiles,
} from '@/types/requests';

type PromptModuleContextType = {
    mandatoryDocumentPick: boolean | null;
    setMandatoryDocumentPick: Dispatch<SetStateAction<boolean>>;
    selectedFollowUp: PublicMessageWithPreviousAndFiles | null;
    setSelectedFollowUp: Dispatch<
        SetStateAction<PublicMessageWithPreviousAndFiles | null>
    >;
    suggestedMission: UseCaseType | null;
    setSuggestedMission: Dispatch<SetStateAction<UseCaseType | null>>;
    selectedMission: MissionsEnum;
    setSelectedMission: Dispatch<SetStateAction<MissionsEnum>>;
    isMissionActive: boolean;
    setIsMissionActive: Dispatch<SetStateAction<boolean>>;
    openLibraryPicker: boolean;
    setOpenLibraryPicker: Dispatch<SetStateAction<boolean>>;
    dataroomOpen: boolean;
    setDataroomOpen: Dispatch<SetStateAction<boolean>>;
    collapsed: boolean;
    setCollapsed: Dispatch<SetStateAction<boolean>>;
    shouldDock: boolean;
    setShouldDock: Dispatch<SetStateAction<boolean>>;
    isDocked: boolean;
    setIsDocked: Dispatch<SetStateAction<boolean>>;
    confirmedSelectedFiles: PublicLibraryFile[];
    setConfirmedSelectedFiles: Dispatch<SetStateAction<PublicLibraryFile[]>>;
    currentConversationId: string | null;
    setCurrentConversationId: Dispatch<SetStateAction<string | null>>;
    selectedLanguage: string;
    setSelectedLanguage: Dispatch<SetStateAction<string>>;
};
export const PromptModuleContext = createContext<PromptModuleContextType>({
    mandatoryDocumentPick: false,
    setMandatoryDocumentPick: () => {},
    selectedFollowUp: null,
    setSelectedFollowUp: () => {},
    selectedMission: 'generic',
    setSelectedMission: () => {},
    suggestedMission: null,
    setSuggestedMission: () => {},
    isMissionActive: false,
    setIsMissionActive: () => {},
    dataroomOpen: false,
    setDataroomOpen: () => {},
    openLibraryPicker: false,
    setOpenLibraryPicker: () => {},
    collapsed: false,
    setCollapsed: () => {},
    shouldDock: false,
    setShouldDock: () => {},
    isDocked: false,
    setIsDocked: () => {},
    confirmedSelectedFiles: [],
    setConfirmedSelectedFiles: () => {},
    currentConversationId: null,
    setCurrentConversationId: () => {},
    selectedLanguage: 'english',
    setSelectedLanguage: () => {},
});

const PromptModuleProvider = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const [currentConversationId, setCurrentConversationId] = useState<
        string | null
    >(null);
    // persist during page transition , helps handling state change between pages
    // /!\ not to be considered a source of truth, if you need to know the current
    // conversation, use the URL instead. (because this state variable will have
    // previous conversationID value for the first render, until updated)
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [mandatoryDocumentPick, setMandatoryDocumentPick] =
        useState<boolean>(false);
    // Julien wants an exception for when we just clicked on the chosen mission with required dataroom :
    // the form should be hidden while a file is not picked. But this behavior does not apply if we unselect a document
    // after having selected it first during the "mandatory" phase. This is why we need a separate state variable.
    const [confirmedSelectedFiles, setConfirmedSelectedFiles] = useState<
        PublicLibraryFile[]
    >([]);
    const [isDocked, setIsDocked] = useState<boolean>(true);
    const [dataroomOpen, setDataroomOpen] = useState<boolean>(false);
    const [openLibraryPicker, setOpenLibraryPicker] = useState(false);
    // isDocked and setIsDocked should not be used inside scrolling computations because rerender is not fast enough (ref instead)
    // we use a state variable to know if the icon to trigger docking should be displayed or not
    const [shouldDock, setShouldDock] = useState<boolean>(false);
    const [selectedFollowUp, setSelectedFollowUp] =
        useState<PublicMessageWithPreviousAndFiles | null>(null);
    const [selectedMission, setSelectedMission] =
        React.useState<MissionsEnum>('generic');
    const [suggestedMission, setSuggestedMission] =
        React.useState<UseCaseType | null>(null);
    const [isMissionActive, setIsMissionActive] =
        React.useState<boolean>(false);
    const [selectedLanguage, setSelectedLanguage] = useState<string>('english');

    // A mission is considered "active" when the prompt module is not collapsed and the mission form is displayed.

    // We can have a "selectedMission" of "factual", with a collapsed prompt module,
    // but if we then click on the 4 square button, the list of missions is to be displayed instead of the "factual" form.
    // In this case, the "factual" mission is selected, but not active.

    // If, instead, the "factual" mission is selected, but the prompt module is collapsed, and we click on the badge
    // with the mission type on it, the prompt module will expand and the "factual" form will be displayed.
    // In this case, the "factual" mission is selected and active.

    const resetPromptModuleState = () => {
        setCollapsed(false);
        setMandatoryDocumentPick(false);
        setConfirmedSelectedFiles([]);
        setIsDocked(true);
        setDataroomOpen(false);
        setOpenLibraryPicker(false);
        setShouldDock(false);
        setSelectedFollowUp(null);
        setSelectedMission('generic');
        setSuggestedMission(null);
        setIsMissionActive(false);
    };

    usePromptModulePageChange(
        resetPromptModuleState,
        currentConversationId,
        setCurrentConversationId
    );

    return (
        <PromptModuleContext.Provider
            value={{
                mandatoryDocumentPick,
                setMandatoryDocumentPick,
                selectedFollowUp,
                setSelectedFollowUp,
                selectedMission,
                setSelectedMission,
                suggestedMission,
                setSuggestedMission,
                isMissionActive,
                setIsMissionActive,
                dataroomOpen,
                setDataroomOpen,
                openLibraryPicker,
                setOpenLibraryPicker,
                collapsed,
                setCollapsed,
                isDocked,
                setIsDocked,
                shouldDock,
                setShouldDock,
                confirmedSelectedFiles,
                setConfirmedSelectedFiles,
                currentConversationId,
                setCurrentConversationId,
                selectedLanguage,
                setSelectedLanguage,
            }}
        >
            {children}
        </PromptModuleContext.Provider>
    );
};
export default PromptModuleProvider;

export function capitalizeFirstLetter(str: string) {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
}

export function computeDiffInMinutes(date1: Date, date2: Date): number {
    const diffInMilliseconds: number = Math.abs(
        date2.getTime() - date1.getTime()
    );
    return Math.round(diffInMilliseconds / (1000 * 60));
}

export async function downloadFile(fileUrl: string, fileName: string) {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
}

export function interpolateToRange(
    value: number,
    max: number,
    range: number
): number {
    if (max === 0) return 0;
    return (Math.min(value, max) / max) * range;
}

'use client';
import { Snackbar } from '@mui/material';
import React from 'react';

const ActionsSnackBars = ({
    copiedSnackbarOpen,
    setCopiedSnackbarOpen,
    downloadError,
    setDownloadError,
    isDownloading,
}: {
    copiedSnackbarOpen?: boolean;
    setCopiedSnackbarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    downloadError?: boolean;
    setDownloadError?: React.Dispatch<React.SetStateAction<boolean>>;
    isDownloading?: boolean;
}) => {
    return (
        <>
            <Snackbar
                open={copiedSnackbarOpen}
                onClose={() => {
                    if (setCopiedSnackbarOpen) {
                        setCopiedSnackbarOpen(false);
                    }
                }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: '#1E2961',
                    },
                }}
                autoHideDuration={1500}
                message={'Copié'}
            />
            <Snackbar
                open={downloadError}
                onClose={() => {
                    if (setDownloadError) {
                        setDownloadError(false);
                    }
                }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: '#DD4425',
                    },
                }}
                autoHideDuration={5000}
                message={'Erreur lors du téléchargement'}
            />
            <Snackbar
                open={isDownloading}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: '#1E2961',
                    },
                }}
                message={'Téléchargement en cours...'}
            />
        </>
    );
};

export default ActionsSnackBars;

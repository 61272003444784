import { UserContext } from '@auth0/nextjs-auth0/client';
import { getSiteDomain, getUserOrganisationKey } from './user';
import { RolesAuth0, OrganizationAuth0 } from '@/types/general';

export const retrieveUserInfo = (
    user: UserContext,
    type: 'roles' | 'organizations'
): [] | RolesAuth0[] | OrganizationAuth0[] => {
    // TODO replace with proper url from env
    if (!user.user) {
        return [];
    }
    if (type === 'roles') {
        return (user?.user[getSiteDomain() + '/roles'] as []) ?? [];
    } else {
        return (user?.user[getUserOrganisationKey()] as []) ?? [];
    }
};

export const isLawfirmAdministrator = (user: UserContext) => {
    const userRoles = retrieveUserInfo(user, 'roles') as RolesAuth0[];
    if (userRoles?.find((role) => role.name === 'lawfirm-administrator')) {
        return true;
    }
    return false;
};

/**



 api.idToken.setCustomClaim(`${namespace}/organizations`, [event.organization]);
 api.accessToken.setCustomClaim(`${namespace}/organizations`, [event.organization]);


 */

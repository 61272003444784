export function getUserOrganisationKey() {
    return `https://app.jimini.ai/organizations`;
}

export function getSiteDomain() {
    if (process.env.STAGE && process.env.STAGE === 'prod') {
        return 'https://app.jimini.ai';
    } else if (process.env.STAGE && process.env.STAGE === 'preprod') {
        return 'https://app.jimini.ai';
    } else if (
        process.env.STAGE &&
        (process.env.STAGE === 'local' || process.env.STAGE === 'timonlocal')
    ) {
        return 'https://d1c7zs3j5efvwx.cloudfront.net';
    } else {
        return 'https://d1c7zs3j5efvwx.cloudfront.net';
    }
}

import apiChatMessage from '@/requests/apis/chatMessage';

export function useAnalytics() {
    const trackMessageAction = async (
        messageId: string,
        action: 'copied' | 'downloaded' | 'translated'
    ) => {
        apiChatMessage.onMessageAnalytics({
            messageId: messageId,
            data: {
                action,
            },
            onSuccess: () => {},
        });
    };
    return { trackMessageAction };
}

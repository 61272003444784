import * as React from 'react';

const WarningBordered = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M12 9v4m0 3v.01M3 12a9 9 0 1 0 18.001 0A9 9 0 0 0 3 12Z'
        />
    </svg>
);
export default WarningBordered;

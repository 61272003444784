import { translationChoices } from '@/helpers/analyzer';
import { FilesSelectedType } from '@/types/analyzer';
import { SummarizationArgsType } from '@/types/missions';

const formatSummarizationQuestionElements = (
    filesNumber: number,
    files: any[]
) => {
    const fallback = {
        fallbackQuestion:
            filesNumber > 1 ? 'Synthèse des documents' : 'Synthèse du document',
        summarizationTextType: '',
        summarizationTextPageRange: '',
        summarizationTextLanguage: '',
        summarizationTextTopics: '',
        summarizationTextOutline: '',
    };

    if (!files.length) {
        return fallback;
    }
    let summarizationArgs: SummarizationArgsType | null = null;
    try {
        // there's a double JSON stringify (encoding) somewhere. I made it so
        // that it could work with 1 or 2 encodings. This way, previously
        // created summary works after we find the source of the double encoding
        summarizationArgs = JSON.parse(
            JSON.parse(files[0]?.summarization_args)
        );
    } catch (e) {
        try {
            summarizationArgs = JSON.parse(files[0]?.summarization_args);
        } catch (e) {}
    }

    if (!summarizationArgs) {
        return fallback;
    }
    const summarizationTextType =
        summarizationArgs.summary_type === 'concise'
            ? 'concise'
            : summarizationArgs.summary_type === 'detailed'
              ? 'détaillée'
              : '';
    const docName = `${files[0]?.library_files?.name ?? files[0]?.name}`;
    const pageRangeNotDefault =
        summarizationArgs?.page_range?.length &&
        summarizationArgs?.page_range[1] &&
        summarizationArgs?.page_range[1] !== -1 &&
        summarizationArgs?.page_range[1] !== 30000;
    const pageRangeOnlyOnePage =
        summarizationArgs?.page_range?.length &&
        summarizationArgs?.page_range[0] === summarizationArgs?.page_range[1] &&
        summarizationArgs?.page_range[0] > 0;
    let summarizationTextPageRange = '';
    if (pageRangeOnlyOnePage) {
        summarizationTextPageRange = ` de la page ${summarizationArgs?.page_range[0] || 1}`;
    } else if (pageRangeNotDefault) {
        summarizationTextPageRange = ` des pages ${summarizationArgs.page_range[0] || 1} à ${
            summarizationArgs.page_range[1]
        }`;
    }
    const summarizationTextLanguage = summarizationArgs.language
        ? ` en ${
              translationChoices?.find(
                  (choice) => choice?.id === summarizationArgs.language
              )?.name
          }`
        : '';
    const summarizationTextTopics = summarizationArgs.topics
        ? ` à propos du thème d'intérêt "${summarizationArgs.topics}"`
        : '';
    const summarizationTextOutline = summarizationArgs.outline
        ? `\n- Plan de la synthèse : \n${summarizationArgs.outline}`
        : '';

    return {
        fallbackQuestion: '',
        summarizationTextType,
        summarizationTextPageRange,
        summarizationTextLanguage,
        summarizationTextTopics,
        summarizationTextOutline,
    };
};
const formatSummarizationQuestion = (filesNumber: number, files: any[]) => {
    const {
        fallbackQuestion,
        summarizationTextType,
        summarizationTextPageRange,
        summarizationTextLanguage,
        summarizationTextTopics,
        summarizationTextOutline,
    } = formatSummarizationQuestionElements(filesNumber, files);
    if (fallbackQuestion) {
        return fallbackQuestion;
    } else {
        return `Rédige une synthèse ${summarizationTextType}${summarizationTextPageRange} ${summarizationTextLanguage} ${summarizationTextTopics} ${summarizationTextOutline}`;
    }
};

const formatMatrixQuestion = (messageText?: any) => {
    const text = messageText ?? [];
    if (text?.length) {
        const deduplicatedText = text.reduce((acc: any[], curr: any) => {
            if (!acc.find((item) => item.query === curr.query)) {
                acc.push(curr);
            }
            return acc;
        }, []);
        return `Matrice de questions\n\n - ${deduplicatedText
            .map((txt: any) => txt.query)
            .join('\n - ')}`;
    }
    return "Matrice d'analyse multi-documents";
};

const postprocessSources = (text: string) => {
    if (!text) return '';
    return text
        .replace(
            /(?:La |la )?(?:S|s)ource \[(\d+)\]/g,
            ':srctag{sourceNumber="$1"}'
        )
        .replace(
            /(?:Les |les )?(?:S|s)ources \[(\d+)\] et \[(\d+)\]/g,
            ':srctag{sourceNumber="$1"} et :srctag{sourceNumber="$2"}'
        )
        .replace(
            /(?:Les |les )?(?:S|s)ources \[(\d+)\], \[(\d+)\] et \[(\d+)\]/g,
            ':srctag{sourceNumber="$1"}, :srctag{sourceNumber="$2"} et :srctag{sourceNumber="$3"}'
        )
        .replace(
            /(?:Les |les )?(?:S|s)ources \[(\d+)\], \[(\d+)\], \[(\d+)\] et \[(\d+)\]/g,
            ':srctag{sourceNumber="$1"}, :srctag{sourceNumber="$2"}, :srctag{sourceNumber="$3"} et :srctag{sourceNumber="$4"}'
        )
        .replace(
            /(?:Les |les )?(?:S|s)ources \[(\d+)\], \[(\d+)\], \[(\d+)\], \[(\d+)\] et \[(\d+)\]/g,
            ':srctag{sourceNumber="$1"}, :srctag{sourceNumber="$2"}, :srctag{sourceNumber="$3"}, :srctag{sourceNumber="$4"} et :srctag{sourceNumber="$5"}'
        )
        .replace(
            /\((sources?|source) \[(\d+)\]/g,
            '(:srctag{sourceNumber="$2"}'
        )
        .replace(/\[(\d+)\]/g, ':srctag{sourceNumber="$1"}')
        .replace(/\\n/g, '\n');
};

const postProcessLinks = (text: string) => {
    return text.replace(
        /^([^\n]*)( \(document (\d+), page (\d+)\))/gm,
        ':linktag{params="$1||$3||$4"}'
    );
};

const postProcessPageRange = (filesSelectedPages: FilesSelectedType[]) => {
    const filesSelectedPagesTransformed =
        filesSelectedPages?.length &&
        filesSelectedPages[0]?.pageStart === 0 &&
        filesSelectedPages[0]?.pageEnd === 0
            ? undefined
            : [filesSelectedPages[0].pageStart, filesSelectedPages[0].pageEnd];
    let defaultPageStart, defaultPageEnd;
    if (
        filesSelectedPagesTransformed?.length &&
        filesSelectedPagesTransformed.length > 1 &&
        filesSelectedPagesTransformed[1]
    ) {
        defaultPageStart =
            filesSelectedPagesTransformed[0] !== null &&
            filesSelectedPagesTransformed[0] > 0
                ? filesSelectedPagesTransformed[0]
                : 1;
        defaultPageEnd =
            filesSelectedPagesTransformed[1] !== null &&
            filesSelectedPagesTransformed[1] > 0
                ? filesSelectedPagesTransformed[1]
                : 30000;
    } else {
        defaultPageStart = 1;
        defaultPageEnd = 30000;
    }
    return [defaultPageStart, defaultPageEnd];
};

export {
    formatMatrixQuestion,
    formatSummarizationQuestion,
    formatSummarizationQuestionElements,
    postProcessLinks,
    postProcessPageRange,
    postprocessSources,
};

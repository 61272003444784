import { useContext } from 'react';
import { PromptModuleContext } from '@/components/Providers/PromptModuleContext';
import { DrawerContext } from '@/components/Providers/DrawerProvider';
import { MissionsEnum } from '@/types/analyzer';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';
import { missionSpecificMapping } from '@/helpers/missions';

const useMissionSelection = () => {
    const {
        setSelectedMission,
        confirmedSelectedFiles,
        setOpenLibraryPicker,
        selectedMission,
        setIsMissionActive,
        setSelectedFollowUp,
        setCollapsed,
        setMandatoryDocumentPick,
        setSuggestedMission,
    } = useContext(PromptModuleContext);

    const { missionDrawerOpen, setMissionDrawerOpen } =
        useContext(DrawerContext);

    const onSelectMission = (typeQuestion: MissionsEnum) => {
        const skipDataroom = !!confirmedSelectedFiles?.length;
        if (missionSpecificMapping[typeQuestion].missionAvailable) {
            if (
                missionSpecificMapping[typeQuestion].hasDataRoom &&
                !skipDataroom
            ) {
                setOpenLibraryPicker(true);
                setMandatoryDocumentPick(true);
            }
            setSuggestedMission(null);
            setSelectedMission(typeQuestion);
            setIsMissionActive(true);
        }
    };

    const resetSelectedMission = () => {
        setSelectedMission('generic');
        setIsMissionActive(false);
    };

    const onClickFollowUp = (message: PublicMessageWithPreviousAndFiles) => {
        if (missionDrawerOpen) {
            setMissionDrawerOpen(false);
        }
        setSelectedFollowUp(message);
        if (selectedMission !== 'factual') {
            setSelectedMission('generic');
        }
        setCollapsed(false);
        if (message.type_question === 'factual') {
            onSelectMission(message.type_question as MissionsEnum);
        }
    };

    return {
        onSelectMission,
        resetSelectedMission,
        onClickFollowUp,
    };
};

export default useMissionSelection;

import React, {
    createContext,
    Dispatch,
    ReactElement,
    SetStateAction,
    useState,
} from 'react';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';

type DrawerContextType = {
    missionDrawerOpen: boolean;
    setMissionDrawerOpen: Dispatch<SetStateAction<boolean>>;
    answerDrawer: PublicMessageWithPreviousAndFiles | null;
    setAnswerDrawer: Dispatch<
        SetStateAction<PublicMessageWithPreviousAndFiles | null>
    >;
};

export const DrawerContext = createContext<DrawerContextType>({
    missionDrawerOpen: false,
    setMissionDrawerOpen: () => {},
    answerDrawer: null,
    setAnswerDrawer: () => {},
});

const DrawerProvider = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const [missionDrawerOpen, setMissionDrawerOpen] = useState(false);
    const [answerDrawer, setAnswerDrawer] =
        useState<PublicMessageWithPreviousAndFiles | null>(null);

    return (
        <DrawerContext.Provider
            value={{
                missionDrawerOpen,
                setMissionDrawerOpen,
                answerDrawer,
                setAnswerDrawer,
            }}
        >
            {children}
        </DrawerContext.Provider>
    );
};
export default DrawerProvider;

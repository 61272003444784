import { useEffect, useMemo, useState } from 'react';
import { getPercentage } from '@/helpers/conversations/chat';
import { UseCaseType } from '@/types/analyzer';
import {
    postProcessLinks,
    postprocessSources,
} from '@/helpers/analyzerMissions';
import { PublicMessageWithPreviousAndFiles } from '@/types/requests';
import { hasPdfInDocs } from '@/helpers/messages';

// handle post processing and smooth appearance of text word-by-word for translation/generic/factual

const usePostProcessMessage = (message: PublicMessageWithPreviousAndFiles) => {
    const [visibleText, setVisibleText] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [percentageComputed, setPercentageComputed] = useState(0);
    const [progressionText, setProgressionText] = useState('');
    const [wordsDisplayed, setWordsDisplayed] = useState(0);
    const [generationDone, setGenerationDone] = useState(false);

    const { status, type_question, created_at, updated_at, text } = message;

    const isGeneratingTranslation = useMemo(() => {
        return (
            status === 'saved' &&
            type_question === 'translation' &&
            !generationDone
        );
    }, [status, type_question, generationDone]);

    const isPdfAnonymization = useMemo(
        () =>
            hasPdfInDocs(message.docs) &&
            message.type_question === 'anonymization',
        [message.docs, message.type_question]
    );

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (status === 'saved' || status === 'error') {
            setPercentage(0);
            setPercentageComputed(0);
            return;
        }
        if (
            status === 'generating' &&
            percentage === 0 &&
            type_question &&
            created_at
        ) {
            interval = setInterval(() => {
                setPercentageComputed(
                    getPercentage(
                        percentage,
                        type_question as UseCaseType,
                        isPdfAnonymization,
                        created_at || '',
                        updated_at || '',
                        text
                    )
                );
            }, 1000);
        } else {
            setPercentageComputed(
                getPercentage(
                    percentage,
                    type_question as UseCaseType,
                    isPdfAnonymization,
                    created_at || '',
                    updated_at || '',
                    text || ''
                )
            );
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [
        status,
        percentage,
        type_question,
        created_at,
        updated_at,
        text,
        isPdfAnonymization,
    ]);

    useEffect(() => {
        const updateText = async () => {
            if (type_question === 'matrice' || type_question === 'search_adb') {
                // Prevents useless computation when called for a matrice
                return;
            }
            if (
                (status === 'saved' || status === 'interrupted') &&
                text === '' &&
                type_question === 'summarization'
            ) {
                setVisibleText('Vous avez interrompu cette synthèse.');
                return;
            }
            let newText = text || '';
            // avoids postprocessing when translating the result of a translation
            // or summarization, as we do not want to render the custom
            // markdowns LinkTag and TooltipInMarkdown because we do not have
            // the associated documents and sources in the translation result

            if (type_question === 'factual') {
                newText = postprocessSources(text as string);
            } else if (type_question === 'summarization') {
                newText = postProcessLinks(newText);
            }

            if (
                status === 'generating' &&
                newText?.includes('Progress') &&
                newText?.includes('%.')
            ) {
                const numberMatch = newText.match(/\d+(\.\d+)?/);
                setPercentage(
                    numberMatch?.length ? parseInt(numberMatch[0]) : 0
                );
                setProgressionText(newText.split('%. ')[1]);
            } else if (status === 'saved') {
                setVisibleText(newText as string);
            } else if (
                status === 'generating' ||
                isGeneratingTranslation ||
                ((status === 'saved' || status === 'interrupted') &&
                    wordsDisplayed > 1)
            ) {
                const newTextSplit = newText.split(' ');
                const displayedTextNumberOfWords =
                    visibleText.split(' ').length;
                if (displayedTextNumberOfWords < newTextSplit.length) {
                    await new Promise<void>((resolve) => {
                        setTimeout(
                            () => {
                                resolve();
                            },
                            type_question === 'translation' ? 10 : 30
                        );
                    });
                    setVisibleText(
                        newTextSplit.slice(0, wordsDisplayed).join(' ')
                    );
                    if (wordsDisplayed < newTextSplit.length) {
                        setWordsDisplayed(wordsDisplayed + 1);
                        // first word displayed + every 20 words, smooth scroll into bottom position
                        if (
                            wordsDisplayed === 1 ||
                            (wordsDisplayed + 1) % 20 === 0
                        ) {
                            window.scrollTo({
                                top: document.body.scrollHeight,
                                behavior: 'smooth',
                            });
                        }
                    }
                } else {
                    if (isGeneratingTranslation) {
                        setGenerationDone(true);
                    }
                }
            } else if (newText !== visibleText) {
                setVisibleText(newText || '');
            }
        };
        updateText();
    }, [
        text,
        wordsDisplayed,
        visibleText,
        status,
        isGeneratingTranslation,
        type_question,
    ]);

    return {
        visibleText,
        percentageComputed,
        progressionText,
    };
};

export default usePostProcessMessage;

import { remark } from 'remark';
import strip from 'strip-markdown';

const removeMarkdown = async (withMarkdownString: string) => {
    const withoutCodeBlock = withMarkdownString.replace(/```/g, '');
    // here we remove "code" markdown before applying the strip plugin
    // because if we use "strip" on text contained inside "code" markdown,
    // it will remove the code block content as well

    const strippedMarkdown = await remark()
        .use(strip)
        .process(withoutCodeBlock);
    return String(strippedMarkdown);
};

export { removeMarkdown };

import { useContext, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { PromptModuleContext } from '@/components/Providers/PromptModuleContext';

const usePromptModulePageChange = (
    resetPromptModuleState: () => void,
    currentConversationId: string | null,
    setCurrentConversationId: (e: string | null) => void
) => {
    const currentPath = usePathname();

    const { setIsDocked } = useContext(PromptModuleContext);
    useEffect(() => {
        setIsDocked(true);
        if (currentPath.includes('new-analyzer/')) {
            const conversationId = currentPath.split('new-analyzer/')[1];
            if (
                currentConversationId &&
                conversationId &&
                conversationId !== currentConversationId
            ) {
                resetPromptModuleState();
                setCurrentConversationId(conversationId);
            } else if (!currentConversationId && conversationId) {
                setCurrentConversationId(conversationId);
            }
        } else {
            setCurrentConversationId(null);
        }
    }, [currentPath, currentConversationId]);
};

export default usePromptModulePageChange;

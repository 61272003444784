'use client';

import { LinearProgress } from '@mui/material';
import React from 'react';

const LinearProgressLoader = ({
    className,
    percentage,
}: {
    className?: string;
    percentage: number;
}) => {
    return (
        <LinearProgress
            variant='determinate'
            value={percentage}
            className={`${className} h-2.5 w-full rounded-full`}
            sx={{
                backgroundColor: '#ECF1F7',
                '& .MuiLinearProgress-bar': {
                    backgroundColor: percentage === 100 ? '#218358' : '#495DB2',
                    borderRadius: '90px',
                },
            }}
        />
    );
};

export default LinearProgressLoader;

import {
    PublicLibraryFileWithArgs,
    PublicMessageWithPreviousAndFiles,
} from '@/types/requests';

const isLastTextualMessageGenerating = (
    message: PublicMessageWithPreviousAndFiles
) => {
    const isTextual =
        message.type_question === 'factual' ||
        message.type_question === 'generic' ||
        message.type_question === 'translation';
    return message.status === 'generating' && isTextual;
};

const getLastTextualMessageGenerating = (
    currentConversationMessages: PublicMessageWithPreviousAndFiles[]
) => {
    const lastMessageWithStatusGenerating = currentConversationMessages
        .toReversed()
        .find(isLastTextualMessageGenerating);
    return lastMessageWithStatusGenerating;
};

const hasPdfInDocs = (docs: PublicLibraryFileWithArgs[]) => {
    return docs.some((doc) => doc.type === 'pdf');
};

export { getLastTextualMessageGenerating, hasPdfInDocs };

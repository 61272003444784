'use client';
import { FormControl, MenuItem, Select } from '@mui/material';
import Image from 'next/image';
import React, { useState } from 'react';

const ActionDropDown = ({
    actions,
    onClickDropDown,
    className,
    renderValue,
    value,
    multiple,
}: {
    actions: {
        value: string;
        name: string;
        disabled?: boolean;
        flag?: string;
        noIcon?: boolean;
    }[];
    onClickDropDown: (value: string) => void;
    className?: string;
    renderValue: (value: string | string[], open: boolean) => React.ReactNode;
    value?: string[];
    multiple?: boolean;
}) => {
    const [open, setOpen] = useState(false);

    return (
        <FormControl variant='outlined' className=''>
            <Select
                open={open}
                onOpen={() => setOpen(true)}
                onClose={(e) => {
                    setOpen(false);
                }}
                onAuxClick={(e) => {
                    setOpen(false);
                }}
                labelId='select-download-type--label'
                id='select-download-type'
                // "multiple" doc : If true, value must be an array and the menu will support multiple selections.
                multiple={multiple}
                //@ts-ignore
                value={value || ''}
                variant='outlined'
                className={`m-0 p-0 pr-0 ${className}`}
                MenuProps={{
                    classes: {
                        paper: 'border border-solid border-[#DBE2EC] rounded-[6px] shadow-[0px_10px_15px_-3px_rgba(0,0,0,0.1)]',
                    },
                }}
                sx={{
                    '& .MuiSelect-select.MuiSelect-outlined': {
                        padding: '6px 8px',
                    },
                    '& .MuiSelect-icon': {
                        display: 'none',
                    },
                    border: 'none',
                    borderRadius: '999px',
                    width: 'fit-content',
                    maxWidth: 'fit-content',
                    minWidth: 'fit-content',
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                        borderStyle: 'none',
                    },
                    '& .MuiPaper-root': {
                        border: 'none',

                        outline: 'none',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',

                        borderRadius: '2px',
                    },
                }}
                label='Pick a value'
                renderValue={(e) => (renderValue ? renderValue(e, open) : null)}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                {actions.map((item) => (
                    <MenuItem
                        onClick={(e) => {
                            onClickDropDown(item.value);
                        }}
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                        className={`py-3 font-gilroyMedium text-solidGray12 hover:bg-alphaBlue3 focus:bg-white focus:hover:bg-alphaBlue3 active:bg-white ${
                            multiple
                                ? !!value?.find((v) => v === item.value)
                                    ? 'bg-solidBlue3'
                                    : ''
                                : ''
                        }`}
                        sx={{
                            '&.Mui-focusVisible': {
                                backgroundColor: 'transparent',
                            },
                            '&.Mui-focusVisible &:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                            '& .MuiTouchRipple-root': {
                                color: '#63B6FF',
                            },
                        }}
                    >
                        {item?.noIcon ? null : !item.flag ? (
                            item.value !== 'xlsx' ? (
                                <Image
                                    priority
                                    src={'/images/doc.png'}
                                    alt='Pdf icon'
                                    width={48}
                                    height={48}
                                    className='-mt-0.5 mr-2 mobile:h-[24px] mobile:w-[24px] tablet:max-h-[24px] tablet:max-w-[24px]'
                                />
                            ) : (
                                <Image
                                    priority
                                    src={'/images/xlsx_icon.png'}
                                    alt='Pdf icon'
                                    width={48}
                                    height={48}
                                    className='-mt-0.5 mr-2 mobile:h-[24px] mobile:w-[24px] tablet:max-h-[24px] tablet:max-w-[24px]'
                                />
                            )
                        ) : (
                            <Image
                                priority
                                src={item.flag}
                                alt='add icon'
                                width={20}
                                height={20}
                                className='mr-3'
                            />
                        )}
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ActionDropDown;

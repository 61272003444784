const choices = [
    {
        name: 'Question directe et factuelle',
        id: 'factual',
        disabled: false,
    },
    { name: 'Question ouverte', id: 'open', disabled: true },
    { name: 'Synthèse', id: 'summarization', disabled: true },
    { name: 'Traduction', id: 'translation', disabled: false },
    { name: 'Autre requête', id: 'generic', disabled: false, alpha: true },
];

// Images taken from https://github.com/HatScripts/circle-flags
const translationChoices = [
    {
        name: 'Anglais',
        id: 'english',
        flag: '/images/V2/circle-flags/gb.svg',
    },
    {
        name: 'Français',
        id: 'french',
        flag: '/images/V2/circle-flags/fr.svg',
    },
    {
        name: 'Allemand',
        id: 'german',
        flag: '/images/V2/circle-flags/de.svg',
    },
    {
        name: 'Arabe',
        id: 'arabic',
        flag: '/images/V2/circle-flags/ar.svg',
    },
    {
        name: 'Chinois',
        id: 'chinese',
        flag: '/images/V2/circle-flags/cn.svg',
    },
    {
        name: 'Coréen',
        id: 'korean',
        flag: '/images/V2/circle-flags/kr.svg',
    },
    {
        name: 'Espagnol',
        id: 'spanish',
        flag: '/images/V2/circle-flags/es.svg',
    },
    {
        name: 'Hindi',
        id: 'hindi',
        flag: '/images/V2/circle-flags/in.svg',
    },
    {
        name: 'Italien',
        id: 'italian',
        flag: '/images/V2/circle-flags/it.svg',
    },
    {
        name: 'Japonais',
        id: 'japanese',
        flag: '/images/V2/circle-flags/jp.svg',
    },
    {
        name: 'Portugais',
        id: 'portuguese',
        flag: '/images/V2/circle-flags/pt.svg',
    },
    {
        name: 'Russe',
        id: 'russian',
        flag: '/images/V2/circle-flags/ru.svg',
    },
    {
        name: 'Suédois',
        id: 'swedish',
        flag: '/images/V2/circle-flags/se.svg',
    },
];

const getLanguageFromId = (id: string) => {
    return translationChoices.find((language) => language.id === id)?.name;
};

const statusMessageToCamelcase = (status: string) => {
    const isLoading = status === 'generating';
    const isDone = status === 'saved';
    const isError = status === 'error';
    const isInterrupted = status === 'interrupted';
    return {
        isLoading,
        isDone,
        isError,
        isInterrupted,
    };
};

export {
    choices,
    translationChoices,
    getLanguageFromId,
    statusMessageToCamelcase,
};

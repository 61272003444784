'use client';
import React, { useEffect } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider as PHProvider } from 'posthog-js/react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { retrieveUserInfo } from '@/helpers/authFunctions';
import { PostHogEvent } from '@/types/posthog';
import { usePathname, useSearchParams } from 'next/navigation';

export function PostHogProvider({ children }: { children: React.ReactNode }) {
    useEffect(() => {
        // It is not possible to dynamically change this key between deployed envs
        // thus, we can only check if it is dev or not
        // (do not use "staging2" "prod" "preprod")
        if (process.env.NEXT_PUBLIC_STAGE === 'dev') {
            return;
        }
        const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
        const posthogHost = process.env.NEXT_PUBLIC_POSTHOG_HOST;

        if (posthogKey && posthogHost) {
            posthog.init(posthogKey, {
                api_host: posthogHost,
            });
        } else {
            console.error(
                'PostHog key or host is missing in the environment variables.'
            );
        }
    }, []);

    return <PHProvider client={posthog}>{children}</PHProvider>;
}

export function usePostHogCapture() {
    const user = useUser();
    const postHogCaptureWithUser = (event: PostHogEvent) => {
        if (posthog.__loaded) {
            const newId = user.user?.sub ?? '';
            posthog.identify(
                newId, // Replace 'distinct_id' with your user's unique identifier
                {
                    email: user.user?.email,
                    organization: retrieveUserInfo(user, 'organizations')[0]
                        ?.name,
                    role: retrieveUserInfo(user, 'roles')[0]?.name,
                }
            );
            posthog.capture(event.event, {
                user_email: user.user?.email,
                ...event.context,
            });
        }
    };
    return { postHogCaptureWithUser };
}

export default function PostHogPageView() {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const { postHogCaptureWithUser } = usePostHogCapture();

    useEffect(() => {
        if (pathname && posthog) {
            let url = window.origin + pathname;
            if (searchParams.toString()) {
                url = url + `?${searchParams.toString()}`;
            }
            postHogCaptureWithUser({
                event: 'pageview',
                context: {
                    current_url: url,
                },
            });
        }
    }, [pathname, searchParams, postHogCaptureWithUser]);

    return null;
}

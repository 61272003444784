import * as React from 'react';

const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M4 20h16m-8-6V4m0 10 4-4m-4 4-4-4'
        />
    </svg>
);
export default DownloadIcon;

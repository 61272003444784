'use client';

import ActionDropDown from '@/components/common_V2/ActionDropDown';
import DownloadIcon from '@/components/Icons/DownloadIcon';
import React from 'react';
import IconButton from '@/components/NewConversations/Messages/ChatbotMessage/AnswersBottomToolbar/IconButton';
import InfiniteLoadingCircle from '@/components/common_V2/InfiniteLoadingCircle';

const matriceExportTypes = [
    {
        value: 'detailed',
        name: 'Word - Explications détaillées et sourcées',
    },
    { value: 'concise', name: 'Word - Réponse courte' },
    { value: 'xlsx', name: 'XLSX - Tableau' },
] as {
    value: 'detailed' | 'concise' | 'xlsx';
    name: string;
}[];

const DownloadAction = ({
    typeQuestion,
    onDownloadIconPress,
    isDownloading,
}: {
    typeQuestion: string;
    onDownloadIconPress: (value?: 'detailed' | 'concise' | 'xlsx') => void;
    isDownloading: boolean;
}) => {
    if (typeQuestion === 'matrice') {
        return (
            <ActionDropDown
                value={['detailed']}
                actions={matriceExportTypes}
                onClickDropDown={(value) => {
                    onDownloadIconPress(
                        value as 'detailed' | 'concise' | 'xlsx'
                    );
                }}
                renderValue={(_selected) => (
                    <div className='-ml-1 pt-1'>
                        {isDownloading ? (
                            <div className='w-fit'>
                                <InfiniteLoadingCircle />
                            </div>
                        ) : (
                            <DownloadIcon
                                className='stroke-solidBlue12'
                                width={22}
                                height={22}
                                viewBox='0 0 24 24'
                            />
                        )}
                    </div>
                )}
            />
        );
    } else {
        return (
            <>
                <IconButton
                    disabled={isDownloading}
                    icon={
                        isDownloading ? (
                            <div className='w-fit'>
                                <InfiniteLoadingCircle />
                            </div>
                        ) : (
                            <DownloadIcon
                                className='stroke-solidBlue12'
                                width={22}
                                height={22}
                                viewBox='0 0 24 24'
                            />
                        )
                    }
                    onClick={() => onDownloadIconPress()}
                />
            </>
        );
    }
};

export default DownloadAction;

import React from 'react';

const ArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={16}
        height={16}
        viewBox='0 0 16 16'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={1.333}
            d='M3.333 8h9.334m0 0-4 4m4-4-4-4'
        />
    </svg>
);
export default ArrowRightIcon;

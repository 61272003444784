import { AnswerMatrixRaw, UseCaseType } from '@/types/analyzer';
import { interpolateToRange } from '@/helpers/utils';

const isTextual = (typeQuestion: UseCaseType) => {
    return ['translation', 'factual', 'generic'].includes(typeQuestion);
};

const fakeProgress = (
    diffInSeconds: number,
    duration: number,
    toPercentage: number
) => {
    if (diffInSeconds > duration) {
        const extraTime = diffInSeconds - duration;
        const decayFactor = Math.exp(-extraTime / duration);
        const progress = toPercentage * (0.8 + 0.2 * (1 - decayFactor));
        return Math.min(progress, toPercentage);
    } else {
        return (diffInSeconds / duration) * toPercentage * 0.8;
    }
};

const getUniqueQuestionsNumberMatrice = (matriceAnswers: AnswerMatrixRaw[]) => {
    const uniquePairs = new Set(
        matriceAnswers.map((item) => `${item.query}-${item.query_format}`)
    );
    return uniquePairs.size;
};

const countTitlesReceived = (
    matriceAnswers: AnswerMatrixRaw[],
    questionsNumber: number
) => {
    return Math.ceil(
        matriceAnswers.reduce((acc, answer) => {
            return acc + (answer.column_title ? 1 : 0);
        }, 0) / questionsNumber
    );
};

const countAnswersReceived = (matriceAnswers: AnswerMatrixRaw[]) => {
    return matriceAnswers.reduce((acc, answer) => {
        return acc + (answer.concise_answer && answer.detailed_answer ? 1 : 0);
    }, 0);
};
const computeMatriceProgress = (diffInSeconds: number, text?: string) => {
    const fakeLoad = fakeProgress(diffInSeconds, 30, 30);
    let matriceAnswers: AnswerMatrixRaw[] = [];
    try {
        if (text) {
            matriceAnswers = JSON.parse(text) as AnswerMatrixRaw[];
        }
    } catch (e) {
        console.error('error parsing matrice json', e, text);
    }
    if (matriceAnswers?.length) {
        const jsonLength = matriceAnswers.length;
        const questionsNumber = getUniqueQuestionsNumberMatrice(matriceAnswers);
        const titlesReceived = countTitlesReceived(
            matriceAnswers,
            questionsNumber
        );
        const answersReceived = countAnswersReceived(matriceAnswers);
        const docsNumber = jsonLength / questionsNumber;
        const titlesProgression = interpolateToRange(
            titlesReceived,
            questionsNumber,
            20
        );
        const answersProgression = interpolateToRange(
            answersReceived,
            Math.round(questionsNumber * docsNumber),
            50
        );
        return 30 + titlesProgression + answersProgression;
    } else {
        return fakeLoad;
    }
};

const getPercentage = (
    percentage: number,
    typeQuestion: UseCaseType,
    isPdfAnonymization: boolean,
    createdAt?: string | Date,
    updatedAt?: string | Date,
    text?: string
) => {
    const now = new Date();
    if (!createdAt) {
        return percentage;
    }
    const createdAtDate = new Date(createdAt);
    const updatedAtDate = updatedAt ? new Date(createdAt) : null;
    let percentageComputed;
    const diffInSeconds = (now.getTime() - createdAtDate.getTime()) / 1000;
    const diffInSecondsUpdated = updatedAtDate
        ? (now.getTime() - updatedAtDate.getTime()) / 1000
        : null;
    const isProgressProvidedMission = [
        'document_translation',
        'magic_wand',
    ].includes(typeQuestion);
    const isSummarization = typeQuestion === 'summarization';
    if (typeQuestion === 'matrice') {
        percentageComputed = computeMatriceProgress(diffInSeconds, text);
    } else if (isSummarization && percentage) {
        percentageComputed = 50 + percentage / 2;
    } else if (isSummarization) {
        percentageComputed = fakeProgress(
            diffInSecondsUpdated || diffInSeconds,
            50,
            50
        );
    } else if (isProgressProvidedMission && percentage) {
        percentageComputed = 10 + percentage / 1.1;
    } else if (isProgressProvidedMission) {
        percentageComputed = fakeProgress(
            diffInSecondsUpdated || diffInSeconds,
            10,
            10
        );
    } else if (typeQuestion === 'search_adb') {
        percentageComputed = fakeProgress(diffInSeconds, 5, 90);
    } else if (isPdfAnonymization) {
        percentageComputed = fakeProgress(diffInSeconds, 40, 99);
    } else if (typeQuestion === 'anonymization') {
        // any other available file type than 'pdf' is faster to process
        percentageComputed = fakeProgress(diffInSeconds, 10, 90);
    } else if (isTextual(typeQuestion)) {
        percentageComputed = fakeProgress(diffInSeconds, 20, 90);
    } else {
        percentageComputed = percentage || 0;
    }
    return Math.min(Math.round(percentageComputed), 100);
};

export { getPercentage };

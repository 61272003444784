import * as React from 'react';

const ArrowFollowUpBottom = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='m15 11 4 4m0 0-4 4m4-4H8a4 4 0 1 1 0-8h1'
        />
    </svg>
);
export default ArrowFollowUpBottom;

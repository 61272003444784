'use client';
import BlinkingCursor from '@/components/Analyzer/BlinkingCursor';
import { h } from 'hastscript';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import directive from 'remark-directive';
import remarkGfm from 'remark-gfm';
import { Node } from 'unist';
import { visit } from 'unist-util-visit';

function htmlDirectives() {
    return transform;

    function transform(tree: Node) {
        visit(
            tree,
            ['textDirective', 'leafDirective', 'containerDirective'],
            ondirective
        );
    }

    function ondirective(node: any) {
        var data = node.data || (node.data = {});
        var hast = h(node.name, node.attributes) as any;

        data.hName = hast.tagName;
        data.hProperties = hast.properties;
    }
}

const AnalyzerMarkdown = ({
    children,
    TooltipInMarkdown,
    LinkTagInMarkdown,
    colorInherit,
    arrayReference,
}: {
    children: string;
    TooltipInMarkdown?: any;
    LinkTagInMarkdown?: (input: any) => React.JSX.Element | '';
    colorInherit?: boolean;
    arrayReference?: any[];
}) => {
    const components = {
        srctag: (props: any) => {
            if (TooltipInMarkdown) {
                return <TooltipInMarkdown {...(props || {})} />;
            }
        },
        referenceTag: (props: { referenceId: number }) => {
            if (TooltipInMarkdown) {
                return (
                    <TooltipInMarkdown
                        {...(props || {})}
                        referenceData={arrayReference?.find(
                            (arrayRefElement) =>
                                arrayRefElement.id === props.referenceId
                        )}
                    />
                );
            }
        },
        linktag: (props: any) => {
            if (LinkTagInMarkdown) {
                return <LinkTagInMarkdown {...(props || {})} />;
            }
        },
        cursor: BlinkingCursor, // Custom tag
        p: ({ _node, ...props }: any) => {
            return (
                <p
                    {...props}
                    className={`${props?.className} my-0 whitespace-pre-line py-0 font-gilroyMedium text-base text-inherit`}
                />
            );
        },
        hr: ({ _node, ...props }: any) => {
            return (
                <hr
                    {...props}
                    className={`${props?.className} mb-0.5 mt-0.5`}
                />
            );
        },
        strong: ({ _node, ...props }: any) => {
            return (
                <strong
                    {...props}
                    className={`${props?.className} mb-0 mt-0.5 py-0 font-gilroyBold font-normal text-inherit`}
                />
            );
        },
        h1: ({ _node, ...props }: any) => {
            return (
                <h1
                    {...props}
                    className={`${props?.className} mb-0 mt-0.5 py-0 font-gilroyMedium text-xl`}
                />
            );
        },
        h2: ({ _node, ...props }: any) => {
            return (
                <h1
                    {...props}
                    className={`${props?.className} mb-0 mt-0.5 py-0 font-gilroyMedium text-lg`}
                />
            );
        },
        h3: ({ _node, ...props }: any) => {
            return (
                <h1
                    {...props}
                    className={`${props?.className} mb-0 mt-0.5 py-0 font-gilroyMedium text-base`}
                />
            );
        },
        h4: ({ _node, ...props }: any) => {
            return (
                <h1
                    {...props}
                    className={`${props?.className} mb-0 mt-0.5 py-0 font-gilroyMedium text-base`}
                />
            );
        },
        li: ({ _node, ...props }: any) => {
            const hasStartingNewline =
                props?.children?.length &&
                typeof props?.children[0] === 'string' &&
                props.children[0]?.startsWith('\n');

            return (
                <li
                    {...props}
                    className={`${
                        props?.className ?? ''
                    } my-0 mb-1 h-fit whitespace-pre-wrap py-0 font-gilroyMedium text-base`}
                >
                    {hasStartingNewline
                        ? props.children.slice(1)
                        : props?.children}
                </li>
            );
        },
        ul: ({ _node, ...props }: any) => {
            return (
                <ul
                    {...props}
                    className={`${
                        props?.className ?? ''
                    } my-0 whitespace-normal py-0 font-gilroyMedium text-base`}
                />
            );
        },
        ol: ({ _node, ...props }: any) => {
            return (
                <ol
                    {...props}
                    className={`${
                        props?.className ?? ''
                    } my-0 whitespace-normal py-0 font-gilroyMedium text-base`}
                />
            );
        },
        code: ({ _node, children, ...props }: any) => {
            // Condition to prevent recursive loop, just in case (because llm)
            return (
                <code
                    {...props}
                    className={`${
                        props?.className ?? ''
                    } my-0 whitespace-pre-wrap py-0 text-sm text-solidGray12`}
                >
                    {colorInherit ? (
                        children
                    ) : (
                        <AnalyzerMarkdown
                            colorInherit
                            TooltipInMarkdown={TooltipInMarkdown}
                        >
                            {children}
                        </AnalyzerMarkdown>
                    )}
                </code>
            );
        },
        pre: ({ _node, ...props }: any) => {
            return (
                <pre
                    {...props}
                    className={`${props?.className ?? ''} bg-alphaGray3`}
                />
            );
        },
    };
    return (
        <div className='markdown-body lg:prose-xl prose max-w-full whitespace-pre-wrap pb-2 pr-4 text-justify font-gilroy text-[16px] leading-relaxed text-inherit'>
            <ReactMarkdown
                //@ts-ignore because of custom plugin not matching ReactMarkdown type
                components={components}
                remarkPlugins={[remarkGfm, directive, htmlDirectives]}
            >
                {children}
            </ReactMarkdown>
        </div>
    );
};

export default AnalyzerMarkdown;

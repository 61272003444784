import React from 'react';

const ArrowSquareBottom = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        fill='none'
        {...props}
    >
        <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='m6 9 6 6 6-6'
        />
    </svg>
);
export default ArrowSquareBottom;

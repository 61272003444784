'use client';
import React from 'react';
import ThreadConversationFilesTooltip from '@/components/Conversations/ThreadConversationFilesTooltip';
import { PublicLibraryFile } from '@/types/requests';

const DocumentsThumbnail = ({
    documents,
    className,
}: {
    documents: PublicLibraryFile[];
    className?: string;
}) => {
    return (
        <div
            className={`${className} dataroom-scrollbar flex flex-row gap-2 overflow-x-auto`}
        >
            {documents?.map((file, index) => {
                return (
                    <ThreadConversationFilesTooltip
                        key={index}
                        files={[file]}
                    />
                );
            })}
        </div>
    );
};

export default DocumentsThumbnail;
